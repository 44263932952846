<template>
  <el-dialog title="添加分类" :visible.sync="openDialog" width="400px" class="dialog-vertical"
    @close="cancel('editForm')">
    <el-form size="medium" :model="_data" :rules="rules" label-position="right" label-width="80px"
      ref="editForm" @submit.native.prevent>
      <el-form-item label="分类名称：" prop="name">
        <el-input type="text" v-model="name" placeholder="请填写分类名称">
        </el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button size="small" @click="openDialog = false">取消</el-button>
      <el-button size="small" type="primary" @click="submit('editForm')" :loading="saveLoading">保存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { saveCategory } from "@/modules/consult/api/consult-category-list/consult-category-list.js";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      saveLoading: false,
      rules: {
        name: [{ required: true, message: "请填写分类名称", trigger: "blur" }],
      },
      name: "",
    };
  },
  methods: {
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          // this.$emit("addFeeConfig",this.form)
          const data = {};
          data.name = this.name;
          data.id = 0;
          saveCategory(data)
            .then((res) => {
              this.$message.success(res.msg);
              this.updateList();
              this.openDialog = false;
              this.saveLoading = false;
            })
            .catch((err) => {
              this.saveLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 取消
    cancel(formName) {
      this.openDialog = false;
      this.$refs[formName].resetFields();
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-vertical {
  ::v-deep.el-textarea__inner {
    width: 360px;
  }
  ::v-deep.el-form-item__label {
    padding-bottom: 0px;
  }
}
</style>
